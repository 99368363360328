// i18next-extract-mark-ns-start capabilities-calibration-rtc

import { LINKS, CALIBRATION_NAV, CAPABILITIES_PRODUCTS_WAVE_APPLICATIONS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilitiesRealTimeCalibrationPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const icon = getImage(data.icon.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							In a traditional approach to calibration this is done on a physical test rig using a high number of physical test points,
							making an overall costly and time-consuming process.
							A virtual simulation replaces much of the requirement for measured data giving fast accurate calibration data all in real-time.
						</p>
						<ul>
							<li>
								Fast, accurate real-time predictive modelling without losing model complexity or fidelity
							</li>
							<li>
								Compatible with industry standards for Software in the Loop (SIL), Hardware in the loop (HIL) and Model in the loop (MIL)
								allowing integration into an existing eco-system
							</li>
							<li>
								Analysis for a range of fuels including non-carbon e-fuels and multi fuel mixes
							</li>
							<li>
								Increased efficiency and less cost through reduced reliance on physical hardware
							</li>
						</ul>
					</Trans>

					{icon && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon}
								alt="WAVE-RT"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_2">
						<p>
							The software WAVE-RT is a tool which replaces the need for a high number of physical test points collected over many hours
							or days to carry out engine calibration.
							It uses measured data to a limited extent to tune a digital twin which generates a data set of calibration simulation results which are used for calibration
							- speeding up the process and reducing costs.
							Replacing the majority of measured data with simulated results saves time and money whilst being fast and accurate
							- delivering results in real-time with no loss in model fidelity or complexity.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_EfficientCalibration")}
						className="widget__sibling__pages">
						<AssetList>
							{CALIBRATION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_PRODUCTS_WAVE_APPLICATIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default CapabilitiesRealTimeCalibrationPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-calibration-rtc", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/calibration/Realis-Simulation_960x720_VirtualCalibration.png" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/products/wave/rs_wavert.png" }) {
		...imageBreaker
	}
}
`;
